@use '../../styles/settings/gutters';
@use '../../styles/settings/colors';
@use '../../styles/settings/fonts';

.contentTypeLabel {
    display: flex;
    align-items: center;
    font-family: fonts.$font-headline;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: colors.$color-primary-500;
    text-transform: uppercase;
    margin-bottom: gutters.$gutter-medium;

    &.reverse {
        color: colors.$color-primary-300;
    }

    &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        background-position: center;
        margin-right: gutters.$gutter-small;
    }

    &.article {
        &::before {  
            background: url('../../public/icons/article.svg') no-repeat; 
        }
    }

    &.book {
        &::before {            
            background: url('../../public/icons/book.svg') no-repeat;
        }
    }

    &.podcast {
        &::before {            
            background: url('../../public/icons/podcast.svg') no-repeat;
        }
    }

    &.workshops {
        &::before {            
            background: url('../../public/icons/course-300.svg') no-repeat;
        }

        &:not(.reverse) {
            &::before {
                background: url('../../public/icons/course-500.svg') no-repeat;
            }
        }
    }

    &.webinar {
        &::before {            
            background: url('../../public/icons/browser-300.svg') no-repeat;
        }

        &:not(.reverse) {
            &::before {
                background: url('../../public/icons/browser-500.svg') no-repeat;
            }
        }
    }

    &.lecture {
        &::before {            
            background: url('../../public/icons/school-300.svg') no-repeat;
        }

        &:not(.reverse) {
            &::before {
                background: url('../../public/icons/school-500.svg') no-repeat;
            }
        }
    }

    &.conference {
        &::before {            
            background: url('../../public/icons/affiliate-300.svg') no-repeat;
        }

        &:not(.reverse) {
            &::before {
                background: url('../../public/icons/affiliate-500.svg') no-repeat;
            }
        }
    }
}
