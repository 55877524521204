@use '../../styles/settings/fonts';
@use '../../styles/settings/colors';

.wrapper {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
    position: absolute;
    bottom: -20px;
    white-space: nowrap;

    &.error {
        color: colors.$color-error;
    }

    &.success {
        color: colors.$color-success;
    }
}