@use '../../styles/settings/colors';
@use '../../styles/settings/gutters';
@use '../../styles/settings/fonts';

.inputWrapper {
    position: relative;
    margin-bottom: gutters.$gutter;

    &.newsletter {
        &::before {
            width: 24px;
            height: 24px;
            content: "";
            display: block;
            background: url("../../public/mail-opened.svg");
            position: absolute;
            left: 12px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
        }

        input {
            padding-left: 42px;
        }
    }

    &.user {
        &::before {
            width: 24px;
            height: 24px;
            content: "";
            display: block;
            background: url("../../public/icons/user.svg");
            position: absolute;
            left: 12px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
        }

        input {
            padding-left: 42px;
        }
    }

    &.phone {
        &::before {
            width: 19px;
            height: 19px;
            content: "";
            display: block;
            background: url("../../public/icons/phone.svg");
            position: absolute;
            left: 12px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
        }

        input {
            padding-left: 42px;
        }
    }

    &.password {
        &::before {
            width: 24px;
            height: 24px;
            content: "";
            display: block;
            background: url("../../public/icons/key.svg");
            position: absolute;
            left: 12px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
        }

        input {
            padding-left: 42px;
        }
    }

    input,
    textarea {
        padding: 12px;
        box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.25);
        border: 1px solid colors.$color-primary-700;
        color: colors.$color-primary-700;
        border-radius: 4px;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        margin: 0;

        &::placeholder {
            color: colors.$color-primary-700;
        }

        &:focus {
            outline: 0;
            background: colors.$color-primary-100;
            border: 1px solid colors.$color-primary;
            box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.55)
        }
    }

    textarea {
        resize: none;
        height: 150px;
        font-family: fonts.$font-content;
    }

    &.success {
        margin-bottom: 30px;
    }

    &.error {
        margin-bottom: 30px;
        
        &.newsletter {
            &::before {
                background: url("../../public/mail-opened-error.svg");
            }
        }

        &.user {
            &::before {
                background: url("../../public/icons/user-error.svg");
            }
        }

        &.phone {
            &::before {
                background: url("../../public/icons/phone-error.svg");
            }
        }

        input,
        textarea {
            border-color: colors.$color-error;
            background: colors.$color-white;
            box-shadow: 0px 0px 0px 2px rgba(234, 53, 53, 0.12);
        }
    }
}
