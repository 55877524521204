$breakpoints: (
    xs: 512px,
    sm: 768px,
    md: 896px,
    lg: 1152px,
    xl: 1280px
);

@mixin bp( $breakpoint ) {
    @if map-has-key( $breakpoints, $breakpoint ) {
       @media ( min-width: #{ map-get( $breakpoints, $breakpoint ) } ) {
         @content;
       }
    } @else if type_of( $breakpoint ) == number and unit( $breakpoint ) == px or unit( $breakpoint ) == em or unit( $breakpoint ) == rem {
       @media (min-width: $breakpoint ) {
         @content;
       }
     } @else {
       @warn "Nie można pobrać żadnej wartości z `#{$breakpoint}`. Nie jest zdefiniowany w mapie `$breakpoints` lub jest nieprawidłową liczbą px, em, lub rem.";
    }
  }