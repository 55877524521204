@use '../../styles/settings/gutters';
@use '../../styles/settings/media';
@use '../../styles/settings/colors';
@use '../../styles/settings/global';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 (gutters.$gutter-big + gutters.$gutter-small);
    padding-top: gutters.$gutter-medium;
}

.mainLogo {
    &.isMenuOpen {
        position: relative;
        filter: brightness(100);
        z-index: 4;

        @include media.bp(md) {
            position: initial;
            filter: none;
            z-index: initial;
        }
    }
}

.menuToggler {
    appearance: none;
    background-color: colors.$color-primary;
    -webkit-mask-image: url('../../public/icons/menu.svg');
    mask-image: url('../../public/icons/menu.svg');
    height: 14px;
    border: 0;
    width: 26px;

    &.isOpen {
        background-color: colors.$color-primary-100;
        z-index: 4;
        position: relative;
    }

    @include media.bp(md) {
        display: none;
    }
}

.langSwitcher {
    position: fixed;
    right: 0;
    top: 15%;
    z-index: 9;
}
