@use '../../styles/settings/fonts';
@use '../../styles/settings/colors';
@use '../../styles/settings/gutters';

.button {
  appearance: none;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 700ms;

  &.primary {
    background: linear-gradient(90.1deg, #0136F8 0.08%, #14A5FF 99.21%);
    box-shadow: 0px 4px 8px rgba(20, 165, 255, 0.24);
    border-radius: 4px;
    padding: gutters.$gutter;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: colors.$color-white;
    font-size: 14px;
    font-weight: 400;
    font-family: fonts.$font-buttons;
    line-height: 1.15;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(90.1deg, #0136F8 0.08%, #14A5FF 67.71%, #14D5FF 99.21%);
    }
  }

  &.secondary {
    padding: gutters.$gutter;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: colors.$color-primary-900;
    background: linear-gradient(90.1deg, rgba(20, 166, 255, 0.14) 0.08%, rgba(20, 165, 255, 0.2) 99.21%), #FFFFFF;
    box-shadow: 0px 4px 8px rgba(20, 165, 255, 0.24);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    font-family: fonts.$font-buttons;
    line-height: 1.15;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), linear-gradient(90.1deg, rgba(20, 166, 255, 0.14) 0.08%, rgba(20, 165, 255, 0.2) 99.21%), #FFFFFF;
      box-shadow: 0px 8px 16px rgba(20, 165, 255, 0.24);
      border-radius: 4px;
    }
  }

  &.lightened {
    padding: gutters.$gutter;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: colors.$color-white;
    background: linear-gradient(90.1deg, #14A6FF 0.08%, rgba(20, 165, 255, 0.86) 99.21%);
    box-shadow: 0px 4px 8px rgba(20, 165, 255, 0.24);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    font-family: fonts.$font-buttons;
    line-height: 1.15;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(90.1deg, #0136F8 0.08%, #14A5FF 67.71%, #14D5FF 99.21%);
    }
  }

  &.clean,
  &.back {
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    font-family: fonts.$font-buttons;
    line-height: 1.15;
    letter-spacing: 0.04em;
    color: colors.$color-primary;
    text-transform: uppercase;
    text-shadow: 0 4px 9px rgba(0, 23, 115, 0.2);
  }

  &.red {
    color: colors.$color-white;
    font-size: 14px;
    font-weight: 400;
    font-family: fonts.$font-buttons;
    line-height: 1.15;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    background: linear-gradient(90.1deg, rgba(234, 53, 53, 0.83) 0.08%, rgba(234, 67, 53, 0.8) 99.21%);
    box-shadow: 0px 4px 8px rgba(234, 67, 53, 0.24);
    border-radius: 4px;
    text-shadow: 0px 4px 9px rgba(109, 23, 16, 0.5);
    padding: gutters.$gutter;

    &:hover {
      background: linear-gradient(90.1deg, rgba(234, 53, 53, 0.83) 0.08%, #EA4335 54.81%, #EA7635 99.21%);
      box-shadow: 0px 8px 16px rgba(234, 67, 53, 0.24);
    }
  }

  &.white {
    padding: 0;
    color: colors.$color-white;
    font-size: 14px;
    font-weight: 400;
    font-family: fonts.$font-buttons;
    line-height: 1.15;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    text-shadow: 0px 4px 9px rgba(0, 23, 115, 0.2);
    transition: 700ms;

    &:hover {
      text-shadow: 0px 8px 16px rgba(0, 23, 115, 0.2);
      transition: 700ms;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
    filter: grayscale(1);
    transition: 700ms;
  }

  .arrow {
    margin-left: gutters.$gutter-small;
  }

  .arrowBack {
    margin-right: gutters.$gutter-small;
  }
}
