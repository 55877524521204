@use '../styles/settings/colors';
@use '../styles/settings/media';

.hiddenOverflow {
    overflow-x: hidden;
}

.darkBackground {
    background-color: colors.$color-primary;
    background-image: url('../public/bg-dark.svg');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 100% auto;
}
