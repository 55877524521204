@use '../../styles/settings/colors';
@use '../../styles/settings/gutters';
@use '../../styles/settings/fonts';
@use '../../styles/settings/media';

.socialMediaLink {
    flex: 0 1 100%;
    margin: 0 0 gutters.$gutter;

    @include media.bp(xs) {
        flex: 0 1 calc(50% - 8px);
        margin: 0 0 gutters.$gutter-medium;
    }

    @include media.bp(md) {
        flex: 1;
        margin-bottom: 0;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    @include media.bp(lg) {
        max-width: 160px;

        &:first-child {
            margin-left: gutters.$gutter;
        }

        &:last-child {
            margin-right: gutters.$gutter;
        }
    }
}

.socialTile {
    border: 1px solid rgba(colors.$color-primary-700, 0.4);
    border-radius: 4px;
    background: radial-gradient(77.73% 1243.75% at 50% 50%, rgba(222, 243, 255, 0.85) 0%, rgba(222, 243, 255, 0) 100%);
    height: 210px;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 146px;

        &.x {
            background: linear-gradient(180deg, #000 0%, #333 100%);
        }

        &.facebook {
            background: linear-gradient(180deg, #4267B2 0%, #578AD7 100%);
        }

        &.instagram {
            background: linear-gradient(52.44deg, #515BD4 0%, #8134AF 27.28%, #DD2A7B 58.89%, #FDD377 90.5%, #FEDA77 90.51%, #F58529 119.03%);
        }

        &.linkedin {
            background: linear-gradient(180deg, #0078B5 0%, #1A93D0 100%);
        }
    }
    
    .name {
        color: colors.$color-primary;
        display: flex;
        font-family: fonts.$font-social-media-titles;
        font-size: 14px;
        line-height: 1.15;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        height: 64px;
        text-shadow: 0px 4px 9px rgba(0, 23, 115, 0.5);
    }
}
