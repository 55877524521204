@use '../../../styles/settings/colors';
@use '../../../styles/settings/gutters';
@use '../../../styles/settings/fonts';

.modal {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    overflow: hidden;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;

    .head {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: colors.$color-primary;
        padding: gutters.$gutter-medium;
    }

    .headContent {
        width: 735px;
        margin: 0 auto;
    }

    .title {
        margin: 0;
        color: colors.$color-white;
        letter-spacing: -0.01em;
        line-height: 1.25;
        font-family: fonts.$font-headline;
        font-size: 16px;
    }

    .subtitle {
        text-transform: uppercase;
        font-size: 14px;
        font-family: fonts.$font-headline;
        color: colors.$color-primary-100;
        margin: 0 0 gutters.$gutter-small;
        letter-spacing: 0.04em;
        line-height: 1.15;
    }

    .overlay {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(32px);
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
    }

    .back {
        position: absolute;
        top: -20px;
        left: 0;
        transform: translateY(-100%);
    }

    .scrollable {
        min-width: 380px;
        max-height: 80vh;
        overflow: auto;
    }

    .content {
        border-radius: 8px;
        background: colors.$color-white;
        box-shadow: 0px 8px 48px rgba(20, 165, 255, 0.11);
        padding: gutters.$gutter + gutters.$gutter-medium;
        position: relative;
        transform: translateY(60px);
        width: 735px;
    }
}