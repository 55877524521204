@use '../../styles/settings/gutters';
@use '../../styles/settings/colors';
@use '../../styles/settings/fonts';
@use '../../styles/settings/media';

.articlePreview {
    flex: 0 1 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    transition: 700ms;

    @include media.bp(sm) {
        flex: 0 1 calc(50% - 8px);
    }

    @include media.bp(lg) {
        flex: 0 1 352px;
      }

    .imageBox {
        margin-bottom: gutters.$gutter-medium;
        filter: drop-shadow(0px 4px 16px rgba(20, 165, 255, 0.16));
        position: relative;
        width: 100%;
        overflow: hidden;

        @include media.bp(sm) {
            height: 216px;
        }

        @include media.bp(lg) {
            overflow: initial;
        }
    }

    .image {
        border-radius: 4px;
        width: 100%;
        object-fit: cover;
    }

    .date {
        color: colors.$color-text;
        font-size: 12px;
        line-height: 1.4;
        font-weight: 400;
        letter-spacing: -0.01em;
        margin-bottom: gutters.$gutter-small * 1.5;
    }

    .title {
        font-size: 16px;
        color: colors.$color-primary;
        line-height: 1.25;
        font-family: fonts.$font-headline;
        letter-spacing: -0.01em;
        margin-bottom: gutters.$gutter-small;
    }

    p {
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: -0.01em;
        color: colors.$color-text;
        margin: 0 0 gutters.$gutter-medium;
    }

    .horizontal {
        display: flex;
        flex-direction: column;
        margin-bottom: gutters.$gutter-medium;

        @include media.bp(md) {
            flex-direction: row;
        }

        > a {
            flex: 1 0 auto;
        }

        .imageBox {
            margin-bottom: 0;
            font-size: 0;
            
            @include media.bp(sm) {
                height: 300px;
            }

            @include media.bp(md) {
                max-width: 400px;
                height: auto;
            }

            @include media.bp(lg) {
                max-width: 500px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 0;
            margin-top: gutters.$gutter;
            justify-content: space-between;
           
            @include media.bp(sm) {
                margin-top: gutters.$gutter * 2;
            }

            @include media.bp(md) {
                margin-left: (gutters.$gutter * 2);
            }
        }

        .title {
            font-size: 24px;
        }
    }

    .external {
        position: absolute;
        z-index: 2;
        top: gutters.$gutter;
        left: gutters.$gutter;
        font-size: 13px;
        text-transform: uppercase;
        color: colors.$color-primary-100;
        background: colors.$color-primary;
        padding: 11px 12px;
        font-family: fonts.$font-headline;
        line-height: 1;
    }
}
