@use '../../styles/settings/colors';
@use '../../styles/settings/gutters';

.imagePlaceholder {
    border-radius: 4px;
    background: colors.$color-primary-100;
    filter: drop-shadow(0px 4px 16px rgba(20, 165, 255, 0.16));
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: gutters.$gutter-medium;

    &.noShadow {
        margin-bottom: 0;
        filter: none;
    }
}
