$color-white: #fff;
$color-black: #111;
$color-primary-900: #001773;
$color-primary-700: #3B4F9F;
$color-primary-500: #0136F8;
$color-primary-300: #14A5FF;
$color-primary-100: #DEF3FF;

$color-border: #F5FCFF;
$color-border-darker: #C0E8F9;

$color-primary: $color-primary-900;
$color-text: $color-primary-700;
$color-text-highlight: #feda77;

$color-lighter-gray: #eee;
$color-light-gray: #cacaca;
$color-dark-gray: #525252;

$color-error: #EA4335CC;
$color-success: #15b06c;
$color-warning: #ff9345;

$color-foundation: #4EBF82;
