@use '../../styles/settings/gutters';
@use '../../styles/settings/fonts';
@use '../../styles/settings/colors';
@use '../../styles/settings/media';

.nav {
    display: none;
    padding: 0 gutters.$gutter;

    @include media.bp(md) {
        display: block;
        padding: 0;
    }

    .list {
        list-style: none;
        display: flex;
        margin: 0;
        padding: gutters.$gutter-big 0 0;
        position: relative;
        height: 100%;

        @include media.bp(md) {
            padding: 0;
            position: static;
        }
    }

    .item {
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: colors.$color-primary;
        font-family: fonts.$font-headline;
        white-space: nowrap;

        @include media.bp(md) {
            font-size: 12px;
        }

        @include media.bp(lg) {
            font-size: 14px;
        }

        &:first-child {
            @include media.bp(md) {
                display: none;
            }

            @include media.bp(lg) {
                display: initial;
            }
        }

        &:not(:last-child) {
            a {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                align-items: center;
                line-height: gutters.$gutter-big;
                padding: 0 gutters.$gutter-small;
                transition: 300ms;
                color: colors.$color-white;

                &::before {
                    content: '';
                    display: block;
                    background-image: url("../../public/icons/arrow-white.svg");
                    width: 38px;
                    height: 6px;
                }

                @include media.bp(md) {
                    color: inherit;

                    &::before {
                        content: none;
                    }
                }

                @include media.bp(lg) {
                    padding: 0 (gutters.$gutter * 0.85);
                }
            }

            @include media.bp(md) {
                &:hover {
                    a {
                        position: relative;
                        border-radius: 4px;
                        transition: 300ms;
                        color: rgb(43, 116, 243);
                        background: -webkit-linear-gradient(180deg, colors.$color-primary-500, #1f62f2);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
        
                        &::after {
                            display: block;
                            content: "";
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background: colors.$color-primary-100;
                            z-index: -1;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }

        &:last-child {
            position: absolute;
            width: 100%;
            bottom: gutters.$gutter;

            @include media.bp(md) {
                margin: 0 0 0 gutters.$gutter;
                position: static;
            }
            
            a {
                font-size: 12px;
                text-align: center;
                display: block;

                @include media.bp(lg) {
                    display: flex;
                    font-size: inherit;
                    text-align: left;
                }
            }
        }

        &.reverse {
            color: colors.$color-white;
        }

        &.search {
            min-width: 32px;
            display: flex;
            align-items: center;
            padding: 0 gutters.$gutter-small;
            margin-top: gutters.$gutter-big;

            @include media.bp(md) {
                justify-content: center;
                margin-top: 0;
                padding: 0;
            }
            
            img {
                cursor: pointer;
            }
        }
    }

    &.isMenuOpen {
        z-index: 3  ;
        display: block;
        position: fixed;
        background: url('../../public/menubg.svg') no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        padding-top: 80px;

        @include media.bp(md) {
            z-index: initial;
            position: initial;
            background: none;
            width: auto;
            height: auto;
            top: initial;
            left: initial;
            padding-top: 0;
        }
        
        .list {
            flex-direction: column;

            @include media.bp(md) {
                flex-direction: row;
            }
        }
    }
}
